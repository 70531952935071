/* eslint no-console:0 */
import "@hotwired/turbo-rails";

import * as Sentry from "@sentry/browser";
import "./add_jquery";
import "bootstrap";
import "bootstrap-datepicker";

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [],
  ignoreErrors: [
    "Non-Error exception captured",
    "Non-Error promise rejection captured",
  ],
  beforeSend(event, hint) {
    if (hint.originalException === "Timeout") return null;
    return event;
  },
});

import "./controllers";

import "./autocomplete";
import "./begin_rtp";
import "./contact";
import "./header";
import "./map_search";
import "./mobile_app_banner";
import "./modal_global";
import "./properties";
import "./property";
import "./search";
import "./us_map";
import "./us_map_svg";
import "./vendors";
import "./videos";
import "./scroll_top";
import "./owl.carousel";
