document.addEventListener("turbo:load", () => {
  const globalModal = document.querySelector("#globalModal");

  if (globalModal) {
    const isVisible = sessionStorage.getItem("global_modal_shown") !== "1";

    globalModal.classList.toggle("d-none", !isVisible);
    globalModal.classList.toggle("show", isVisible);
    globalModal.style.display = isVisible ? "block" : "none";

    globalModal
      .querySelector(".btn-close, [data-bs-dismiss='modal']")
      ?.addEventListener("click", () => {
        globalModal.classList.add("d-none");
        globalModal.classList.remove("show");
        globalModal.style.display = "none";
        sessionStorage.setItem("global_modal_shown", "1");
      });
  }
});
